import React from "react";

import "./Team.scss";

import { useTranslation } from "react-i18next";

const Team = () => {
  const { t } = useTranslation();

  const navigateToTeam = () => {
    const currentLang = window.location.pathname.split("/")[1];
    window.location.href = `/${currentLang}/team`;
  };

  return (
    <div className="team-container">
      <div className="team-text">
        <h2>{t("ourTeam")}</h2>
        <p>{t("teamBody")}</p>
        <div className="buttons-container">
          {/* <div className="main-button" onClick={navigateToTeam}>
            {t("joinusTitle")}
          </div> */}
          <div className="alt-button" onClick={navigateToTeam}>
            {t("ourMembers")}
          </div>
        </div>
      </div>
      <div className="team-image">
        <img src="/img/team.jpg" alt="" />
      </div>
    </div>
  );
};

export default Team;
