import React from "react";
import { Element } from "react-scroll";

import "./Team.scss";
import "../index.scss";

import NavBar from "../components/NavBar/NavBar.tsx";
import Hero from "../components/TeamPage/Hero/Hero.tsx";
import Footer from "../components/Footer/Footer.tsx";
import Members from "../components/TeamPage/Members/Members.tsx";
import JoinUs from "../components/TeamPage/JoinUs/JoinUs.tsx";
import Affiliated from "../components/TeamPage/Affiliated/Affiliated.tsx";
import useIsMobile from "../hooks/useIsMobile.ts";

const Team = () => {
  const { isMobile } = useIsMobile();

  return (
    <>
      <NavBar />
      <div className="content" id="content">
        {isMobile && <Element name="hero" id="hero"></Element>}
        {!isMobile && (
          <Element name="hero" id="hero" className="section">
            <Hero />
          </Element>
        )}
        <Element name="members" id="members" className="section alt">
          <Members />
        </Element>
        <Element name="joinus" id="joinus" className="section">
          <JoinUs />
        </Element>
        <Element name="branches" id="affiliated" className="section alt">
          <Affiliated />
        </Element>
        <Footer />
      </div>
    </>
  );
};

export default Team;
