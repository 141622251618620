import React from "react";

import "./Hero.scss";
import { useTranslation } from "react-i18next";

const Hero = () => {
  const { t} = useTranslation();

  return (
    <div className="hero-content">
      <h1 className="title">
        {t("teamTitle")}
      </h1>
    </div>
  );
};

export default Hero;
