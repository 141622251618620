import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

// Translation resources
const resources = {
  en: {
    translation: {
      aboutTitle: "About",
      partnerTitle: "Partners",
      faqTitle: "FAQ",
      teamTitle: "Our team",
      membersTitle: "Members",
      joinusTitle: "Join us",
      affiliatedBranchesTitle: "Affiliated branches",
      hackathon: "Hackathon",
      contact: "Contact",
      email: "E-mail",
      registerButton: "Register",
      eventDate: "8 - 9 Febuary, 2025",
      days: "Days",
      hours: "Hours",
      minutes: "Minutes",
      seconds: "Seconds",
      infoTitle: "What is PolyHACKS?",
      statTitle: "PolyHACKS in numbers",
      hackers: "hackers",
      projects: "projects",
      inRewards: "in rewards",
      ourPartners: "Our partners",
      becomeAPartner: "Become a partner",
      plan: "Sponsorship plan",
      ourTeam: "Our team",
      ourMembers: "Our members",
      joinUs: "Join us!",
      polyAiDesc: "The #1 artificial intelligence club at Polytechnique Montreal.",
      polyCyberDesc: "Polytechnique's cybersecurity club.",
      polyCsDesc: "Club responsible for Polytechnique's CSGames delegation.",
      more: "Tell me more",
      infoBody: "With over 400 participants each year, PolyHacks is proud to be the largest bilingual hackathon in Canada. This 24-hour marathon, where teams come together to tackle unique challenges, is much more than just a competition. It’s a memorable event, filled withsurprises, prizes, and incredible energy,fully orchestrated by a passionatestudent community, in collaborationwith partners like you.", 
      teamBody: "PolyHx, the team behind PolyHacks, is a passionate student community organizing the largest bilingual hackathon in Canada. With a human-centered approach, it is dedicated to leveraging technology to address tomorrow’s challenges while promoting inclusion and diversity. Composed primarily of students in computer and software engineering, the team warmly welcomes anyone sharing a passion for technological innovation. With 30% of its members being women, it reflects its commitment to equity and representation. Through strategic partnerships, PolyHx supports underrepresented communities and inspires future generations to embrace science and technology.",
      // TODO: Add all translations
    },
  },
  fr: {
    translation: {
      aboutTitle: "À propos",
      partnerTitle: "Partenaires",
      faqTitle: "FAQ",
      teamTitle: "Notre équipe",
      membersTitle: "Membres",
      joinusTitle: "S'impliquer",
      affiliatedBranchesTitle: "Branches affiliées",
      hackathon: "Hackathon",
      contact: "Contact",
      email: "Courriel",
      registerButton: "S'inscrire",
      eventDate: "8 - 9 Février 2025",
      days: "Jours",
      hours: "Heures",
      minutes: "Minutes",
      seconds: "Secondes",
      infoTitle: "Qu'est-ce que PolyHACKS ?",
      statTitle: "PolyHACKS, c'est aussi...",
      hackers: "hackers",
      projects: "projets",
      inRewards: "en prix",
      ourPartners: "Nos partenaires",
      becomeAPartner: "Devenir partenaire",
      plan: "Plan de commandite",
      ourTeam: "Notre équipe",
      ourMembers: "Nos membres",
      joinUs: "Rejoignez-nous !",
      polyAiDesc: "La société technique d'intelligence artificielle de Polytechnique Montréal.",
      polyCyberDesc: "La société technique de cybersécurité de Polytechnique Montréal.",
      polyCsDesc: "Société technique responsable de la délégation de Polytechnique Montréal aux CSGames.",
      more: "En savoir plus",
      infoBody: "Avec plus de 400 participants chaque année, PolyHacks est fier d’être le plus grand hackathon bilingue au Canada. Ce marathon de 24 heures, où des équipes de participants se réunissent pour relever des défis uniques, est bien plus qu’une simple compétition. C’est un événement mémorable, plein de surprises, de prix et d’une énergie incroyable, entièrement orchestré par une communauté étudiante passionnée, en collaboration avec des partenaires comme vous.",
      teamBody: "PolyHx, l'équipe derrière PolyHacks, est une communauté étudiante passionnée qui organise le plus grand hackathon bilingue au Canada. Avec une approche centrée sur l'humain, elle s'engage à utiliser la technologie pour répondre aux défis de demain tout en favorisant l'inclusion et la diversité. Composée d'étudiants majoritairement en génie informatique et logiciel, l'équipe accueille chaleureusement toutes les personnes partageant une passion pour l'innovation technologique. Avec 30 % de ses membres étant des femmes, elle reflète son engagement envers l'équité et la représentation. Grâce à des partenariats stratégiques, PolyHx soutient les communautés sous-représentées et inspire les générations futures à embrasser les sciences et la technologie.",
    },
  },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: "fr",
    detection: {
      order: ["path", "navigator"],
      lookupFromPathIndex: 0,
    },
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
