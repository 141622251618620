import React from "react";
import {
  FaFacebook,
  FaInstagram,
  FaLinkedin,
  FaEnvelope,
  FaRegCopyright,
} from "react-icons/fa";

import "./Footer.scss";
import { globalConfig } from "../../config/config.ts";
import { Link } from "react-scroll";
import { useTranslation } from "react-i18next";
import { NAVBAR_OFFSET } from "../../consts/consts.ts";
import { useLocation } from "react-router-dom";

const Footer = () => {
  const { t, i18n } = useTranslation();

  const location = useLocation();
  const isHomePage = /^\/(?:[a-z]{2})\/home\/?$/.test(location.pathname);

  return (
    <div className="footer-container">
      <div className="title-container">
        <h2 className="title">PolyHACKS</h2>
        <h3 className="subtitle">
          <i>PolyHx</i>
        </h3>
      </div>
      <div className="footer-nav-container">
        <div className="nav-category">
          <h3 className="label">{t("hackathon")}</h3>
          {isHomePage && (
            <>
              <Link
                className="nav-option"
                to="info"
                smooth={true}
                duration={400}
                offset={-NAVBAR_OFFSET}
              >
                {t("aboutTitle")}
              </Link>
              <Link
                className="nav-option"
                to="partner"
                smooth={true}
                duration={400}
                offset={-NAVBAR_OFFSET}
              >
                {t("partnerTitle")}
              </Link>
              <Link
                className="nav-option"
                to="faq"
                smooth={true}
                duration={400}
                offset={-NAVBAR_OFFSET}
              >
                {t("faqTitle")}
              </Link>
            </>
          )}
          {!isHomePage && (
            <>
              <a className="nav-option" href={`/${i18n.language}/home`}>
                {t("aboutTitle")}
              </a>
              <a className="nav-option" href={`/${i18n.language}/home`}>
                {t("partnerTitle")}
              </a>
              <a className="nav-option" href={`/${i18n.language}/home`}>
                {t("faqTitle")}
              </a>
            </>
          )}
          {globalConfig.isSubscriptionEnabled ? (
            <a
              className="nav-option"
              href="https://forms.gle/XnbNYHKBfGTiA5e78"
              target="_blank"
              rel="noreferrer noopener"
            >
              {t("registerButton")}
            </a>
          ) : null}
        </div>
        <div className="nav-category">
          <h3 className="label">{t("teamTitle")}</h3>
          {isHomePage && (
            <>
              <a className="nav-option" href={`/${i18n.language}/team`}>
                {t("membersTitle")}
              </a>
              <a className="nav-option" href={`/${i18n.language}/team`}>
                {t("joinusTitle")}
              </a>
              <a className="nav-option" href={`/${i18n.language}/team`}>
                {t("affiliatedBranchesTitle")}
              </a>
            </>
          )}
          {!isHomePage && (
            <>
              <Link
                className="nav-option"
                to="members"
                smooth={true}
                duration={400}
                offset={-NAVBAR_OFFSET}
              >
                {t("membersTitle")}
              </Link>
              <Link
                className="nav-option"
                to="joinus"
                smooth={true}
                duration={400}
                offset={-NAVBAR_OFFSET}
              >
                {t("joinusTitle")}
              </Link>
              <Link
                className="nav-option"
                to="branches"
                smooth={true}
                duration={400}
                offset={-NAVBAR_OFFSET}
              >
                {t("affiliatedBranchesTitle")}
              </Link>
            </>
          )}
        </div>
        <div className="nav-category">
          <h3 className="label">{t("contact")}</h3>
          <a
            className="nav-option"
            href="https://www.facebook.com/PolyHx"
            target="_blank"
            rel="noreferrer noopener"
          >
            <FaFacebook /> Facebook
          </a>
          <a className="nav-option" href="https://www.instagram.com/polyhx/">
            <FaInstagram /> Instagram
          </a>
          <a
            className="nav-option"
            href="https://www.linkedin.com/company/polyhxio/"
          >
            <FaLinkedin /> LinkedIn
          </a>
          <a className="nav-option" href="mailto:sponsorspolyhx@gmail.com">
            <FaEnvelope />
            {t("email")}
          </a>
        </div>
      </div>
      <div className="copyright">
        <FaRegCopyright /> PolyHx
      </div>
    </div>
  );
};

export default Footer;
