import React from "react";
import { Element } from "react-scroll";

import "./Home.scss";
import "../index.scss";

import NavBar from "../components/NavBar/NavBar.tsx";
import Hero from "../components/HomePage/Hero/Hero.tsx";
import Info from "../components/HomePage/Info/Info.tsx";
import Partners from "../components/HomePage/Partners/Partners.tsx";
import Faq from "../components/HomePage/Faq/Faq.tsx";
import Team from "../components/HomePage/Team/Team.tsx";
import Footer from "../components/Footer/Footer.tsx";

const Home = () => {
  return (
    <>
      <NavBar />
      <div className="content" id="content">
        <Element name="hero" id="hero" className="section">
          <Hero />
        </Element>
        <Element name="info" id="info" className="section alt">
          <Info />
        </Element>
        <Element name="partner" id="partner" className="section">
          <Partners />
        </Element>
        <Element name="faq" id="faq" className="section alt">
          <Faq />
        </Element>
        <Element name="team" id="team" className="section">
          <Team />
        </Element>
        <Footer />
      </div>
    </>
  );
};

export default Home;
