import React, { useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { FaChevronDown } from "react-icons/fa";

import "./NavBar.scss";
import { globalConfig } from "../../config/config.ts";
import { Link } from "react-scroll";
import { NAVBAR_OFFSET } from "../../consts/consts.ts";
import useIsMobile from "../../hooks/useIsMobile.ts";

const NavBar = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement | null>(null);

  const { isMobile, isMedium } = useIsMobile();

  const { t, i18n } = useTranslation();

  const navigate = useNavigate();

  const location = useLocation();
  const isHomePage = /^\/(?:[a-z]{2})\/home\/?$/.test(location.pathname);

  const changeLanguage = (lang) => {
    const currentPath = window.location.pathname.split("/").slice(2).join("/");
    navigate(`/${lang}/${currentPath}`);
    i18n.changeLanguage(lang);
  };

  const currentLanguage = i18n.language;

  return (
    <nav>
      <div className="nav-container">
        {isHomePage && (
          <Link
            className="nav-item"
            to="hero"
            smooth={true}
            duration={400}
            offset={-NAVBAR_OFFSET}
          >
            {isMobile && (
              <img src="/img/favicon-96x96.png" alt="PolyHx logo"></img>
            )}
            {!isMobile && (
              <h1 className="logo">
                Poly<span className="secondary-color">HACKS</span>
              </h1>
            )}
          </Link>
        )}
        {!isHomePage && (
          <a className="nav-item" href={`/${i18n.language}/home`}>
            {isMobile && (
              <img src="/img/favicon-96x96.png" alt="PolyHx logo"></img>
            )}
            {!isMobile && (
              <h1 className="logo">
                Poly<span className="secondary-color">HACKS</span>
              </h1>
            )}
          </a>
        )}

        <ul className="nav-links">
          {!isMedium && isHomePage && (
            <>
              <li>
                <Link
                  className="nav-item"
                  to="info"
                  smooth={true}
                  duration={400}
                  offset={-NAVBAR_OFFSET}
                >
                  {t("aboutTitle")}
                </Link>
              </li>
              <li>
                <Link
                  activeClass="active"
                  className="nav-item"
                  to="partner"
                  smooth={true}
                  duration={400}
                  offset={-NAVBAR_OFFSET}
                >
                  {t("partnerTitle")}
                </Link>
              </li>
              <li>
                <Link
                  className="nav-item"
                  to="faq"
                  smooth={true}
                  duration={400}
                  offset={-NAVBAR_OFFSET}
                >
                  {t("faqTitle")}
                </Link>
              </li>
            </>
          )}
          {!isMedium && !isHomePage && (
            <>
              <a className="nav-item" href={`/${i18n.language}/home`}>
                {t("aboutTitle")}
              </a>
              <a className="nav-item" href={`/${i18n.language}/home`}>
                {t("partnerTitle")}
              </a>
              <a className="nav-item" href={`/${i18n.language}/home`}>
                {t("faqTitle")}
              </a>
            </>
          )}

          {!isMedium && (
            <li
              className="all-mouse-events"
              onMouseEnter={() => setIsDropdownOpen(true)}
              onMouseLeave={() => setIsDropdownOpen(false)}
            >
              <Link
                className="nav-item"
                to={isHomePage ? "team" : "hero"}
                smooth={true}
                duration={400}
                offset={-NAVBAR_OFFSET}
              >
                {t("teamTitle")}
                <span id="chevron">
                  <FaChevronDown />
                </span>
              </Link>

              <div
                ref={dropdownRef}
                className={`dropdown-menu ${isDropdownOpen ? "active" : ""}`}
              >
                <ul>
                  {isHomePage && (
                    <>
                      <li>
                        <a
                          className="dropdown-item"
                          href={`/${i18n.language}/team`}
                        >
                          {t("membersTitle")}
                        </a>
                      </li>
                      <li>
                        <a
                          className="dropdown-item"
                          href={`/${i18n.language}/team`}
                        >
                          {t("joinusTitle")}
                        </a>
                      </li>
                      <li>
                        <a
                          className="dropdown-item"
                          href={`/${i18n.language}/team`}
                        >
                          {t("affiliatedBranchesTitle")}
                        </a>
                      </li>
                    </>
                  )}
                  {!isHomePage && (
                    <>
                      <li>
                        <Link
                          className="dropdown-item"
                          to="members"
                          smooth={true}
                          duration={400}
                          offset={-NAVBAR_OFFSET}
                        >
                          {t("membersTitle")}
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="dropdown-item"
                          to="joinus"
                          smooth={true}
                          duration={400}
                          offset={-NAVBAR_OFFSET}
                        >
                          {t("joinusTitle")}
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="dropdown-item"
                          to="branches"
                          smooth={true}
                          duration={400}
                          offset={-NAVBAR_OFFSET}
                        >
                          {t("affiliatedBranchesTitle")}
                        </Link>
                      </li>
                    </>
                  )}
                </ul>
              </div>
            </li>
          )}
          {globalConfig.isSubscriptionEnabled ? (
            <li>
              <a
                className="nav-item main-action"
                href="https://forms.gle/XnbNYHKBfGTiA5e78"
                target="_blank"
                rel="noreferrer noopener"
              >
                {t("registerButton")}
              </a>
            </li>
          ) : null}
          <li>
            {currentLanguage !== "en" && (
              // eslint-disable-next-line jsx-a11y/anchor-is-valid
              <a className="nav-item" onClick={() => changeLanguage("en")}>
                EN
              </a>
            )}
            {currentLanguage !== "fr" && (
              // eslint-disable-next-line jsx-a11y/anchor-is-valid
              <a className="nav-item" onClick={() => changeLanguage("fr")}>
                FR
              </a>
            )}
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default NavBar;
