import React from "react";

import "./Hero.scss";
import CountdownTimer from "../CountdownTimer/CountdownTimer.tsx";
import { globalConfig } from "../../../config/config.ts";
import { useTranslation } from "react-i18next";

const Hero = () => {
  const { t } = useTranslation();

  return (
    <div className="hero-content">
      <h1 className="logo">
        Poly<span className="secondary-color">HACKS</span>
      </h1>
      <h2 className="date">{t("eventDate")}</h2>
      <CountdownTimer targetDate={new Date("2025-02-08T00:00:00")} />
      {globalConfig.isSubscriptionEnabled ? (
        <a
          className="main-action"
          href="https://forms.gle/XnbNYHKBfGTiA5e78"
          target="_blank"
          rel="noreferrer noopener"
        >
          {t("registerButton")}
        </a>
      ) : null}
    </div>
  );
};

export default Hero;
