import { useState, useEffect } from 'react';

function useResponsive(mobileBreakpoint = 768, mediumBreakpoint = 1000) {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= mobileBreakpoint);
  const [isMedium, setIsMedium] = useState(window.innerWidth <= mediumBreakpoint);

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      setIsMobile(width <= mobileBreakpoint);
      setIsMedium(width <= mediumBreakpoint);
    };

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, [mobileBreakpoint, mediumBreakpoint]);

  return { isMobile, isMedium };
}

export default useResponsive;
