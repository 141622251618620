import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useParams,
  Navigate,
} from "react-router-dom";
import Home from "./Home/Home.tsx";
import { useTranslation } from "react-i18next";
import Team from "./Team/Team.tsx";

function App() {
  const { lng } = useParams(); // Get language from URL
  const { i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(lng); // Change language when URL changes
  }, [lng, i18n]);

  return (
    <Routes>
      <Route path="/" element={<Navigate to="/fr/home" replace />} />

      <Route path="/:lng/home" element={<Home />} />
      <Route path="/:lng/team/" element={<Team />} />
    </Routes>
  );
}

export default App;
