import React from "react";

import "./Members.scss";
import { membersConfig } from "../../../config/config.ts";
import { useTranslation } from "react-i18next";
import { FaLinkedin, FaGithub, FaGlobe } from "react-icons/fa";

const Hero = () => {
  const { t, i18n } = useTranslation();

  return (
    <div className="members-container">
      <h1 className="members-title">{t("ourMembers")}</h1>

      <div className="members">
        {membersConfig.members.map((member) => (
          <div className="member" key={member.fullName}>
            <div className="img-container">
              <img
                src={member.path}
                alt={member.fullName}
                width={190}
                height={180}
              />
            </div>
            <h3>{member.fullName}</h3>
            <p>{i18n.language === 'fr' ? member.role: member.englishRole}</p>
            <div className="socials">
              {member.linkedin && (
                <a
                  href={member.linkedin}
                  target="_blank"
                  rel="nooponer noreferrer"
                >
                  <FaLinkedin size={30} color="white"></FaLinkedin>
                </a>
              )}
              {member.github && (
                <a
                  href={member.github}
                  target="_blank"
                  rel="nooponer noreferrer"
                >
                  <FaGithub size={25} color="white"></FaGithub>
                </a>
              )}
              {member.website && (
                <a
                  href={member.website}
                  target="_blank"
                  rel="nooponer noreferrer"
                >
                  <FaGlobe size={25} color="white"></FaGlobe>
                </a>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Hero;
