import React from "react";

import "./JoinUs.scss";

import { useTranslation } from "react-i18next";

const JoinUs = () => {
  const { t } = useTranslation();

  return (
    <div className="joinus-container">
      <div className="joinus-image">
        <img src="/img/event.png" alt="" />
      </div>
      <div className="joinus-text">
        <h2>{t("joinUs")}</h2>
        <p>
          {t("teamBody")}
        </p>
        {/* <div className="buttons-container">
          <div className="main-button">
            Rejoignez notre équipe
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default JoinUs;
