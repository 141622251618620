import React from "react";

import "./Question.scss";

import { Question } from "../../../interfaces/QuestionsConfig.ts";
import { FaChevronDown } from "react-icons/fa";

interface QuestionProps {
  question: Question;
}

const QuestionComponent = (props: QuestionProps) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const answerRef = React.useRef<HTMLParagraphElement>(null);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div
      className={`question-container ${isOpen ? "open" : ""}`}
      onClick={toggleOpen}
    >
      <div className="first-row">
        <p className="question-text">{props.question.question}</p>
        <span id="chevron">
          <FaChevronDown size={32} />
        </span>
      </div>
      <div
        className="answer"
        style={{
          height: isOpen ? `${answerRef.current!.offsetHeight}px` : "0",
        }}
      >
        <p className="answer-text" ref={answerRef}>
          {props.question.answer}
        </p>
      </div>
    </div>
  );
};

export default QuestionComponent;
