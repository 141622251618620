import { QuestionsConfig } from '../interfaces/QuestionsConfig';
import { GlobalConfig } from '../interfaces/GlobalConfig';
import { PartnersConfig } from '../interfaces/PartnersConfig';
import { MembersConfig } from '../interfaces/MembersConfig';

import questionsConfigEn from './questions_en.json';
import questionsConfigFr from './questions_fr.json';
import globalConfig from './global.json';
import partnersConfig from './partnersConfig.json';
import membersConfig from './membersConfig.json';

const typedQuestionsConfigEn: QuestionsConfig = questionsConfigEn;
const typedQuestionsConfigFr: QuestionsConfig = questionsConfigFr;
const typedGlobalConfig: GlobalConfig = globalConfig;
const typedPartnersConfig: PartnersConfig = partnersConfig;
const typedMembersConfig: MembersConfig = membersConfig;


export { typedQuestionsConfigEn as questionsConfigEn };
export { typedQuestionsConfigFr as questionsConfigFr };
export { typedGlobalConfig as globalConfig };
export { typedPartnersConfig as partnersConfig };
export { typedMembersConfig as membersConfig };