import React, { useState, useEffect } from "react";

import "./CountdownTimer.scss";
import { useTranslation } from "react-i18next";

const CountdownTimer = ({ targetDate }: { targetDate: Date }) => {
  const { t } = useTranslation();

  const [timeLeft, setTimeLeft] = useState<{
    days: number;
    hours: number;
    minutes: number;
    seconds: number;
  }>({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  useEffect(() => {
    const interval = setInterval(() => {
      const now = new Date().getTime();
      const distance = targetDate.getTime() - now;

      if (distance < 0) {
        clearInterval(interval);
        setTimeLeft({ days: 0, hours: 0, minutes: 0, seconds: 0 });
        return;
      }

      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);

      setTimeLeft({ days, hours, minutes, seconds });
    }, 1000);

    return () => clearInterval(interval);
  }, [targetDate]);

  return (
    <div className="timer-container">
      <div className="number-container">
        <span className="countdown-item">{timeLeft.days.toString().padStart(2, "0")}</span>
        <div className="countdown-label">{t("days")}</div>
      </div>
      <span className="countdown-item">:</span>
      <div className="number-container">
        <span className="countdown-item">{timeLeft.hours.toString().padStart(2, "0")}</span>
        <div className="countdown-label">{t("hours")}</div>
      </div>
      <span className="countdown-item">:</span>
      <div className="number-container">
        <span className="countdown-item">{timeLeft.minutes.toString().padStart(2, "0")}</span>
        <div className="countdown-label">{t("minutes")}</div>
      </div>
      <span className="countdown-item">:</span>
      <div className="number-container">
        <span className="countdown-item">{timeLeft.seconds.toString().padStart(2, "0")}</span>
        <div className="countdown-label">{t("seconds")}</div>
      </div>
    </div>
  );
};

export default CountdownTimer;
