import React from "react";

import "./Faq.scss";

import { questionsConfigFr, questionsConfigEn } from "../../../config/config.ts";
import QuestionComponent from "../Question/Question.tsx";
import { useTranslation } from "react-i18next";

const Faq = () => {
  const { i18n } = useTranslation();

  const questionsConfig = i18n.language === "fr" ? questionsConfigFr : questionsConfigEn;

  return (
    <div className="faq-container">
      <h2>FAQ</h2>
      <div className="question-section">
        <div className="question-row">
          {questionsConfig.questions.map((question, index) => index % 2 === 0 ? (
            <QuestionComponent question={question} key={question.question} />
          ) : null)}
        </div>
        <div className="question-row">
          {questionsConfig.questions.map((question, index) => index % 2 === 1 ? (
            <QuestionComponent question={question} key={question.question} />
          ) : null)}
        </div>
      </div>
    </div>
  );
};

export default Faq;
