import React from "react";

import "./Affiliated.scss";
import { useTranslation } from "react-i18next";

const Affiliated = () => {
  const { t } = useTranslation();

  return (
    <div className="affiliated-container">
      <h1 className="affiliated-title">{t("affiliatedBranchesTitle")}</h1>
      <div className="branches">
        <div className="branch">
          <h2 className="subtitle">PolyAi</h2>
          <img
            src="/img/branches/polyai.png"
            alt="PolyAI"
            width={125}
            height={125}
          />
          <p className="description">{t("polyAiDesc")}</p>
          <a
            className="main-button"
            href="https://polyai.ca/"
            target="_blank"
            rel="noreferrer noopener"
          >
            {t("more")}
          </a>
        </div>
        <div className="branch">
          <h2 className="subtitle">PolyCyber</h2>
          <img
            src="/img/branches/polycyber.png"
            alt="PolyCyber"
            width={125}
            height={125}
          />
          <p className="description">{t("polyCyberDesc")}</p>
          <a
            className="main-button"
            href="https://polycyber.io/"
            target="_blank"
            rel="noreferrer noopener"
          >
            {t("more")}
          </a>
        </div>
        <div className="branch">
          <h2 className="subtitle">PolyCSGames</h2>
          <img
            src="/img/branches/polycs.png"
            alt="PolyCSGames"
            width={170}
            height={125}
          />
          <p className="description">{t("polyCsDesc")}</p>
          <a
            className="main-button"
            href="https://www.instagram.com/polycsgames/"
            target="_blank"
            rel="noreferrer noopener"
          >
            {t("more")}
          </a>
        </div>
      </div>
    </div>
  );
};

export default Affiliated;
